
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  background: black;
}

h1, h4, p, a {
  color: white;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 248, 58);
  color: black;
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  background: transparent;
  color: #fff;
}

.btn:hover{
  background: lightgrey;
  color: #fff;
  transition: 0.3s;
}