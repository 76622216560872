@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap');


.main-header{
  font-family: 'IM Fell English SC', serif;
  width: 100%;
  height: 30px;
}

.intro-page{
  width: 100%;
  height: 70px;
  font-family: 'IM Fell English SC', serif;
}