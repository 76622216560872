label{
    color: white;
    margin-bottom: 0.5rem;
}

form{
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}

form input, form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255, 255,255,0.1 );
    border-color: rgba(255, 255,255,0.1 );
    color: #f4f4f4;
}

form span{
    color: white;
    padding-top: 5px;
}
