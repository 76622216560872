.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}

.intro-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.7;
  }

  .hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left:50%;
    /* color: white; */
    text-align: center;
    padding: 0.6rem 0 1.5rem;
   }
 
   .hero .content h2{
       padding: 0.6rem 0 1.5rem;
    }
 
    .hero .content .h1{
       font-size: 4rem;
       padding: 0.6rem 0 1.5rem;
    }

    .hero .content p{
        font-size: 1.4rem;
        font-weight: 400;
        font-family: cursive;
        text-transform: inherit;
        /* text-transform: uppercase; */
        padding: 0.6rem 0 1.5rem;
  
     }

     @media screen and (max-width:640px){
        .hero .content h1{
           font-size: 3rem;
        }
     
        .hero .content h2 p{
           font-size: 1.4rem;
        }
     
     
     }